import router from "@/router";
import {
	mapState
} from "vuex";

import moment from "moment";

export default {
	data() {
		return {
			API_LINK: process.env.NODE_ENV !== "production" ? process.env.VUE_APP_API_ROOT :
			'', //全局资源路径 导出使用  例如 let url = this.API_LINK + '/api/giftEmpList'
		};
	},
	computed: {
		...mapState(['vuex_user', 'vuex_type_list']),
		mix_user_avatar() {
			return this.vuex_user.image || ''
		}
	},
	watch: {

	},
	filters: {

	},
	methods: {
		get_vuex_type(id) { //this.options = this.get_vuex_type(1)
			//this.$log('获取配置项', this.vuex_type_list)

			let configInfo = this.vuex_type_list.find(v => v.id == id)
			let configList = configInfo.child || []
			return configList
		},
		//获取部门标题
		mix_format_bumen_title(titles) {
			if(!titles) {
				titles = ''
			}
			let title_arr = titles.split('/')
			let title = title_arr[title_arr.length - 1]
			return title || ''
		},

		$log(text, data) {
			if (typeof data == 'object') {
				try {
					data = JSON.parse(JSON.stringify(data))
				} catch (e) {
					console.error('logjson 异常', e)
				}
			}
			console.warn('logjson => ', text, ' => ', data);
		}
	}
};