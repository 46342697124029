<template>
	<div class="bumen-select">
		<el-cascader v-model="bumen_select_value" :options="bumen_tree_data" :props="bumen_tree_props" clearable
			@change="onchange"></el-cascader>
	</div>
</template>
<script>
	export default {
		name: "bumen-select",
		props: {
			is_show_position: {//是否展示部门下的岗位数据
				type: Boolean,
				default: false
			},
			multiple: {//部门 是否可多选
				type: Boolean,
				default: false
			},
		},
		data() {
			return {
				bumen_select_value: [3, 11, 12],
				bumen_tree_data: [],
			};
		},
		computed: {
			bumen_tree_props() {
				let bumen_tree_props ={
					multiple: this.multiple,//是否多选
					checkStrictly: true,//是否严格的遵守父子节点不互相关联
					value: 'id',
					label: 'title',
					children: 'child',
				}
				return bumen_tree_props
			}
		},

		watch: {

		},

		created() {
	
			this.query_bumen_tree()
		},

		methods: {
			
			init(option) {
				// this.$log('回显 部门选择 init option', option)
				if (option.type == '设置数据') {
					if (option.value) {
						let data = JSON.parse(JSON.stringify(option.value));
						// this.bumen_select_value = this.multiple ?  data : [data]
						this.bumen_select_value = data;
						// this.$log('部门数据回显',this.bumen_select_value)
					}
				}
			},

			query_bumen_tree() {
				this.query_all_department()
			},
			//所有组织架构
			query_all_department() {
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					if (res.code == 200) {
						let list = res.data;
						this.handle_cate_list(list)
					}
				});
			},

			//处理所有分类数据
			handle_cate_list(list) {
				console.log('处理部门数据')

				let cate_level_map = {
					// 1: [], 
					// 2: [], 
				};
				list.forEach(v => {
					let {
						level
					} = v;
					if (!cate_level_map[level]) {
						cate_level_map[level] = []
					}
					cate_level_map[level].push(v)
				})

				this.cate_flat_list = list;
				this.cate_level_map = cate_level_map;
				// this.$log('cate_level_map', cate_level_map)


				this.handle_tree(1);
				this.bumen_tree_data = this.cate_level_map[1];
				// this.$log('部门树数据', this.bumen_tree_data)
			},

			handle_tree(curr_deep = 1) {
				let next_deep = curr_deep + 1
				let curr_list = this.cate_level_map[curr_deep] || []
				if (curr_list && curr_list.length) {
					this.cate_level_map[curr_deep].forEach(par => {
						let child_all = this.cate_level_map[next_deep] || []
						let child = child_all.filter(son => son.parent_id == par.id)

						//是否包含岗位
						let child_has_position = child.some(son => son.is_gw > 0);
						if (!child_has_position) {
							par.child = child;
						} else {
							if (this.is_show_position) { //需要展示岗位
								par.child = child;
							} else {
								par.child = [];
							}
						}
					})
				}
				if (this.cate_level_map[next_deep]) {
					this.handle_tree(next_deep)
				}
			},
			onchange(value) {
				// this.$log('部门级联选择 选项值：', value)
				let bumen_items = []
				if(this.multiple) {
						
				} else {
					value.forEach(id => {
						let bumen_item  = this.cate_flat_list.find(v => v.id == id)
						bumen_items.push(bumen_item)
					})
				}
				
				let bumen_titles = bumen_items.map(v => v.title).join('/') || ''
				
				this.$emit('confirm', value, bumen_items, bumen_titles, )
			}

		},

	};
</script>

<style scoped lang="less">
	.bumen-select {
		width: 100%;

		.el-cascader {
			width: 100%;
		}
	}
</style>